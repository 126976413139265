import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

export const H1 = styled.h1`
  ${p => p.theme.typography.tH1}  
`

export const H2 = styled.h2`
  ${p => p.theme.typography.tH2}  
`

export const H3 = styled.h3`
  ${p => p.theme.typography.tH3}  
`

export const H4 = styled.h4`
  ${p => p.theme.typography.tH4}
`

export const H5 = styled.h5`
  ${p => p.theme.typography.tH5}
`

export const H6 = styled.h6`
  ${p => p.theme.typography.tH6}
`

export const Paragraph = styled.p`
  ${p => p.theme.typography.tNormal}
`

export const ParagraphSmall = styled.p`
  ${p => p.theme.typography.tSmall}
`

export const ParagraphXLarge = styled.p`
  ${p => p.theme.typography.tXLarge}
`

export const ButtonLink = styled(Link)`
  display: inline-block;
  font-size: 14px;
  padding: 5px 20px;
`

export const LinkDecoration = css`
  position: relative;
  display: inline-block;

  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 0;
    height: 1px;
    bottom: 0px;
    right: 0;
    left: auto;
    background-color: ${p => p.theme.colors.white};
    z-index: 1;
    will-change: width;
    transition: 
      right 0s ease 300ms,
      left 0s ease 300ms,
      width 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  ${p => p.trigger}:hover > &:after,
  &:hover:after, 
  &.is-active:after {
    width: 100%;
    left: 0;
    right: auto;
    transition: 
      width 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  }
`

export const LinkDecorated = styled(Link)`
  ${LinkDecoration}
`

export const ADecorated = styled.a`
  ${LinkDecoration}
`

export const ButtonDecorated = styled.button`
  ${LinkDecoration}
`

import React from 'react'

import {
  BreakpointProvider,
  setDefaultBreakpoints
} from 'react-socks'

import { breakpoints } from 'styles/media'

setDefaultBreakpoints(
  Object
    .entries(breakpoints)
    .map(([name,val]) => ({[name]: val}))
)

const Breakpoint = ({ children }) => {
  return (
    <BreakpointProvider>
      {children}
    </BreakpointProvider>
  )
}

export default Breakpoint

export default function facebookAddToCart(variant) {
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    const data = {
      content_name: variant.product.title, 
      content_category: variant.product.productType || 'Clothing',
      content_ids: variant.product.sku,
      content_type: 'product',
      value: variant.price,
      currency: 'USD' 
    }
    console.log('data >>> ', data)
    window.fbq('track', 'AddToCart', data)
  }
}
import React, { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import gsap from 'gsap'
import { useLocation } from '@reach/router'

import MiniLineItem from 'components/MiniLineItem'

import {
  Link
} from 'gatsby'

import {
  Container
} from 'components/Grid'

import {
  ButtonPrimary
} from 'components/Button'

import {
  Paragraph
} from 'components/Typography'

import {
  getPrice
} from 'utils/product'

import usePrevious from 'hooks/usePrevious'

import StoreContext from 'context/Store'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.3);
  user-select: none;
  visibility: hidden;
  opacity: 0;
  z-index: 2;  
`

const Pane = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 85vw;
  height: 100%;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;  
  overflow: hidden;
  background-color: ${p => p.theme.colors.green};
  max-width: 720px;

  ${p => p.theme.media.minWidth('tablet')} {
    width: 75vw;
  }

  ${p => p.theme.media.minWidth('tablet-large')} {
    width: 60vw;
  }

  ${p => p.theme.media.minWidth('desktop')} {
    width: 50vw;        
  }
`

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${p => p.theme.header.mobileHeight};
  flex: 0 0 ${p => p.theme.header.mobileHeight};
  border-bottom: solid 1px ${p => p.theme.colors.ltGreen};  

  ${p => p.theme.media.minWidth('tablet')} {
    height: ${p => p.theme.header.tabletHeight};
    flex: 0 0 ${p => p.theme.header.tabletHeight};
    justify-content: space-between;
    padding: 0 ${p => p.theme.grid.containerPadding.tablet}px;
    border-bottom: none;  
  }

  ${p => p.theme.media.minWidth('desktop')} {
    height: 80px;
    flex: 0 0 80px;
  }
`

const HeaderTitle = styled.div`
  ${p => p.theme.typography.tMedium};  
`

const Close = styled.button`
  ${p => p.theme.mixins.vCenter('absolute')}
  right: 5px;
  padding: 10px;

  ${p => p.theme.media.minWidth('tablet')} {
    position: relative;
    transform: none;
    top: auto;
    right: auto;
    margin-right: -10px;
  }

  i {
    font-size: 24px;
    color: ${p => p.theme.colors.ltGreen};
    ${p => p.theme.media.minWidth('tablet')} {
      color: ${p => p.theme.colors.cream};
      font-size: 16px;
    }
  }
`

const Body = styled.div`
  position: relative;
  flex: 1 1 auto;
  text-align: left;
  overflow-y: scroll;
`

const Footer = styled.div`
  position: relative;
  padding: 30px 0 30px 0;

  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`

const Subtotal = styled.div`
  margin: 0 0 20px 0;
  text-align: left;

  & ${Paragraph} {
    margin: 0;
  }
`

const SubtotalTitlePrice = styled.div`
  display: flex;
  justify-content: space-between;  
`

const SubtotalTitle = styled.div`
  ${p => p.theme.typography.tMedium};
  margin: 0 0 15px 0;

  ${p => p.theme.media.minWidth('tablet')} {
    margin: 0 0 5px 0;    
  }
`

const SubtotalPrice = styled.div`
  ${p => p.theme.typography.tMedium};
`

const Checkout = styled(ButtonPrimary)`
  width: 100%;
`

const MiniCart = () => {  
  const {
    miniCartOpen,
    setMiniCartOpen,
    checkout
  } = useContext(StoreContext)
  const location = useLocation()
  const prevLocation = usePrevious(location)
  
  const wrapperRef = useRef()
  const paneRef = useRef()
  
  useEffect(() => {
    const tl = gsap.timeline()
    
    if ( miniCartOpen ) {      
      tl.to(wrapperRef.current, .5, {
        autoAlpha: 1,
        ease: 'power2.out'
      })
      
      tl.to(paneRef.current, .5, {
        x: 0,
        ease: 'power4.out'
      }, '-=.2')
    } else {
      tl.to(paneRef.current, .5, {
        x: '100%',
        ease: 'power4.out'
      })
      
      tl.to(wrapperRef.current, .5, {
        autoAlpha: 0,
        ease: 'power4.out'
      }, '-=.15')
    }
  }, [miniCartOpen])


  useEffect(() => {
    if ( location !== prevLocation )
      setMiniCartOpen(false)    
  }, [location, prevLocation, setMiniCartOpen])

  const onWrapperClick = e => {
    if ( (miniCartOpen && !paneRef.current.contains(e.target)) ) 
      setMiniCartOpen(false)    
  }

  const onCheckoutClick = () => {
    window.open(checkout.webUrl)
  }
    
  return (
    <Wrapper ref={wrapperRef} onClick={onWrapperClick}>
      <Pane ref={paneRef}>
        <Header>
          <HeaderTitle>
            Shopping Bag ({checkout.lineItems.length})
          </HeaderTitle>
          <Close onClick={() => setMiniCartOpen(false)}>
            <i className='icon-close'></i>
          </Close>
        </Header>
        <Body>
          {checkout.lineItems.map((lineItem, i) => (
             <MiniLineItem {...lineItem} key={i} />
           ))}

          {checkout.lineItems.length === 0 && (
             <Container>
               <Paragraph>You bag is currently empty.</Paragraph>
             </Container>
           )}
        </Body>
        
        {checkout.lineItems.length > 0 && (
           <Footer>
             <Container>
               <Subtotal>
                 <SubtotalTitlePrice>
                   <SubtotalTitle>Subtotal</SubtotalTitle>
                   <SubtotalPrice>
                     {getPrice(checkout.subtotalPrice.attrs.amount, {
                        minimumFractionDigits: 2
                      })}
                   </SubtotalPrice>
                 </SubtotalTitlePrice>
                 <Paragraph>Tax and shipping calculated at checkout</Paragraph>              
               </Subtotal>
               <Checkout onClick={onCheckoutClick}>Checkout</Checkout>            
               <Paragraph>
                 <Link to='/bag'>View Bag</Link>
               </Paragraph>
             </Container>          
           </Footer>
         )}
      </Pane>
    </Wrapper>
  )
}

export default MiniCart

import React, { useState } from 'react'

import {
  ThemeProvider
} from 'styled-components'

import * as media from 'styles/media'
import colors from 'styles/colors'
import header from 'styles/header'
import * as mixins from 'styles/mixins'
import * as grid from 'styles/grid'
import * as typography from 'styles/typography'
import * as button from 'styles/button'

const Theme = ({ children }) => {
  const [headerPadding, setHeaderPadding] = useState(true)
  
  return (
    <ThemeProvider theme={{
      media,
      header,
      colors,
      mixins,
      grid,
      typography,
      button,
      global: {
        setHeaderPadding,
        headerPadding
      }
    }}>      
      {children}
    </ThemeProvider>
  )
}

export default Theme

import React from 'react'
import App from 'layout/App'
import StoreProvider from 'provider/Store'
import ThemeProvider from 'provider/Theme'
import BreakpointProvider from 'provider/Breakpoint'

import 'scss/app.scss'

export const shouldUpdateScroll = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
  return false
}

export const wrapRootElement = ({ element }) => {
  return (
    <StoreProvider>      
      <ThemeProvider>
        <BreakpointProvider>
          {element}
        </BreakpointProvider>
      </ThemeProvider>      
    </StoreProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {  
  return ( 
    <App {...props}>{element}</App>
  )
}

import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;

  svg {
    ${props => props.theme.mixins.fill('absolute')}
  }
`

const Svg = ({ filename, width, height }) => {
  const File = require(`images/${filename}.svg`)
  
  
  return (
    <Wrapper css={`padding-top: ${(height / width) * 100}%`}>
      <File />
    </Wrapper>
  )
}

export default Svg

import { useState, useEffect } from 'react'

const isBrowser = typeof window !== 'undefined'

function getWindowDimensions() {
  if ( !isBrowser )
    return {
      width: 0,
      height: 0
    }
  
  const {
    innerWidth: width,
    innerHeight: height
  } = window
  
  return {
    width,
    height
  }
}

const useWindowDimensions = () => {    
  const [
    windowDimensions,
    setWindowDimensions
  ] = useState(getWindowDimensions())

  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    isBrowser && window.addEventListener('resize', onResize)
    
    return () => isBrowser && window.removeEventListener('resize', onResize)
  }, [])

  return windowDimensions
}

export default useWindowDimensions

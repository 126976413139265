// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bag-js": () => import("./../../../src/pages/bag.js" /* webpackChunkName: "component---src-pages-bag-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-alphabetical-link-list-page-js": () => import("./../../../src/templates/AlphabeticalLinkListPage.js" /* webpackChunkName: "component---src-templates-alphabetical-link-list-page-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/Collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-modular-page-js": () => import("./../../../src/templates/ModularPage.js" /* webpackChunkName: "component---src-templates-modular-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-rich-text-page-js": () => import("./../../../src/templates/RichTextPage.js" /* webpackChunkName: "component---src-templates-rich-text-page-js" */)
}


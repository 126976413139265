export const containerPadding = {
  'mobile': 15,
  'tablet': 30
}

export const columnGutter = {
  'mobile': 15,
  'tablet': 22
}

export const columnCount = {
  'mobile': 24,
  'tablet': 24
}

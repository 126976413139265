import { css } from 'styled-components'

export const primaryCss = css`
  display: inline-block;
  font-size: 14px;
  padding: 10.5px 25px;
  letter-spacing: .025em;
  border: solid 1px ${p => p.theme.colors.xxdkGreen};
  background-color: ${p => p.theme.colors.xxdkGreen};
  transition: 
    border-color 400ms cubic-bezier(0.25, 0.1, 0.25, 1),
    background-color 400ms cubic-bezier(0.25, 0.1, 0.25, 1);

  ${p => p.theme.media.minWidth('tablet')} {
    font-size: 13px;
  }

  &:hover, 
  ${p => p.hoverTrigger}:hover & {
    border-color: ${p => p.theme.colors.ltGreen};
    background-color: ${p => p.theme.colors.ltGreen};
  }
`

export const secondaryCss = css`
  font-size: 14px;
  letter-spacing: .025em;
  padding: 10.5px 25px;
  color: ${p => p.theme.colors.black};
  background-color: ${p => p.theme.colors.white};
  transition: 
    color 300ms cubic-bezier(0.25, 0.1, 0.25, 1),
    background-color 300ms cubic-bezier(0.25, 0.1, 0.25, 1);

  ${p => p.theme.media.minWidth('tablet')} {
    font-size: 13px;
  }

  &:hover, 
  ${p => p.hoverTrigger}:hover & {
    color: ${p => p.theme.colors.white};
    background-color: ${p => p.theme.colors.ltGreen};
  }
`

export const tertiaryCss = css`
  font-size: 14px;
  letter-spacing: .025em;
  padding: 10.5px 25px;  
  color: ${p => p.theme.colors.white};
  background-color: transparent;
  border: solid 1px ${p => p.theme.colors.ltGreen};
  transition: 
    color 300ms cubic-bezier(0.25, 0.1, 0.25, 1),
    background-color 300ms cubic-bezier(0.25, 0.1, 0.25, 1);

  ${p => p.theme.media.minWidth('tablet')} {
    font-size: 13px;
  }

  &:hover, 
  ${p => p.hoverTrigger}:hover & {
    color: ${p => p.theme.colors.white};
    background-color: ${p => p.theme.colors.ltGreen};
  }
`

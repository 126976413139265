import React, { useContext, useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Breakpoint } from 'react-socks'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Container
} from 'components/Grid'

import {
  getUrl,
  getPrice
} from 'utils/product'

import StoreContext from 'context/Store'

const LineItem = styled.div`  
  margin: 20px 0;
  padding: 0 0 20px 0;
  border-bottom: solid 1px ${p => p.theme.colors.ltGreen};
  text-align: left;

  & > * {
    opacity: ${p => p.disabled ? .3 : 1};
    pointer-events: ${p => p.disabled ? 'none' : 'all'};
    transition: opacity 200ms ease;
  }

  ${p => p.theme.media.minWidth('tablet')} {
    margin: 24px 0;
    padding: 0;
    border-bottom: none;
  }
`

const LineItemContainer = styled(Container)`
  display: flex;  

  ${p => p.theme.media.minWidth('tablet')} {
    align-items: center;
  }
`

const Image = styled.div`
  position: relative;
  flex: 0 0 75px;
  margin: 0 10px 0 0;
  background-color: ${p => p.theme.colors.ltGreen}; 

  ${p => p.theme.media.minWidth('tablet')} {
    flex: 0 0 120px;
    margin: 0 20px 0 0;
  }

  ${p => p.theme.media.minWidth('desktop')} {
    margin: 0 35px 0 0;
  }

  img {
    max-width: 100%;
  }
`

const Details = styled.div`
  position: relative;
  flex: 1;
`

const VendorTitle = styled.div`
  display: flex;
  justify-content: space-between;
`

const Vendor = styled.div`
  ${p => p.theme.typography.tNormal};
  text-transform: uppercase;
`

const Title = styled.div`
  ${p => p.theme.typography.tNormal};
`

const Options = styled.ul`
  ${p => p.theme.typography.tNormal};
  margin: 0;
  padding: 0;
  list-style: none;
  
  li {
    margin: 4px 0;
  }
`

const PriceWrapper = styled.div`
  ${p => p.theme.typography.tNormal};

  ${p => p.theme.media.minWidth('tablet')} {
    margin: 20px 0 0 0;
  }  
`

const Price = styled.span`
  display: inline-block;
`

const CompareAtPrice = styled.span`
  display: inline-block;
  margin: 0 10px 0 0;
  text-decoration: line-through;
`

const RemoveWrapper = styled.div`
  ${p => p.theme.media.minWidth('tablet')} {
    ${p => p.theme.mixins.vCenter('absolute')};
    right: 0;
  }
`

const Remove = styled.button`
  ${p => p.theme.typography.tNormal};
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

const MiniLineItem = ({
  title,
  variant,
  customAttributes,
  ...props
}) => {
  const {
    removeLineItem
  } = useContext(StoreContext)

  const [disabled, setDisabled] = useState(false) 
  
  const vendorAttr = customAttributes
    .find(attr => attr.key === 'vendor')
  const vendor = vendorAttr && vendorAttr.value

  const onRemoveClick = () => {
    setDisabled(true)
    removeLineItem(props.id).then(() => {      
      setDisabled(false)
    })
  }

  const renderCompareAtPrice = () => {
    if ( !variant.compareAtPrice ) return
    
    const pcompareAtPrice = parseFloat(variant.compareAtPrice.attrs.amount.value)
    const pprice = parseFloat(variant.price.attrs.amount.value)
    
    if ( pprice >= pcompareAtPrice ) return
    
    return (
      <CompareAtPrice>{getPrice(variant.compareAtPrice.attrs.amount.value)}</CompareAtPrice>
    )
  }

  const renderPrice = () => (
    <PriceWrapper>
      {renderCompareAtPrice()}
      <Price>{getPrice(variant.price.attrs.amount.value)}</Price>
    </PriceWrapper>
  )

  const renderImage = () => {
    if ( variant.image ) {
      return (
        <img src={variant.image.src} alt={variant.product.title} />
      )
    } else {
      return (
        <StaticImage
            src='../images/product-card-placeholder.jpg'
            alt='' />
      )
    }      
  }
  
  return (
    <LineItem disabled={disabled}>
      <LineItemContainer>
        <Image>
          <Link to={getUrl(variant.product.handle)}>            
            {renderImage()}
          </Link>
        </Image>
        <Details>
          <VendorTitle>
            {vendor && <Vendor>{vendor}</Vendor>}
            <Breakpoint mobile-large down>
              {renderPrice()}
            </Breakpoint> 
          </VendorTitle>
          
          <Title>{title}</Title>
          
          <Options>
            {variant.selectedOptions.map(({ name, value }, i) => (
               <li key={i}>{name}: {value}</li>
             ))}
          </Options>

          <Breakpoint tablet up>
            {renderPrice()}
          </Breakpoint>

          <RemoveWrapper>
            <Remove onClick={onRemoveClick}>Remove</Remove>
          </RemoveWrapper>
        </Details>
      </LineItemContainer>
    </LineItem>
  )
}

export default MiniLineItem

const colors = {
  black:     '#000000',
  xxltGreen: '#EBEDE6',
  xltGreen:  '#C2C5BA',
  ltGreen:   '#ACB1A2',
  green:     '#6B725E',
  dkGreen:   '#414833',
  xdkGreen:  '#373D2B',
  xxdkGreen: '#2F3425',
  gray:      '#828282',
  beige:     '#E4DBCF',
  cream:     '#FDFBF5', 
  white:     '#ffffff'
}

export default colors

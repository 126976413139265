import React from 'react'
import styled from 'styled-components'

import MobileMenu from 'components/MobileMenu'
import DesktopMenu from 'components/DesktopMenu'
import UserMenu from 'components/UserMenu'

const Nav = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 100%;  

  ${p => p.theme.media.minWidth('tablet')} {
    padding: 0 30px;
  }
`

const Navigation = () => {    
  return (
    <Nav>
      <MobileMenu />
      <DesktopMenu />
      <UserMenu />
    </Nav>
  )
}

export default Navigation

import { useStaticQuery, graphql } from "gatsby";

const useLogo = () => {
  const { allContentfulDefaultMetadataLogo } = useStaticQuery(
    graphql`
      query MyQuery {
        allContentfulDefaultMetadataLogo {
          edges {
            node {
              defaultLogo {
                gatsbyImageData
              }
            }
          }
        }
      }
    `
  );

  // Need to cleanup the url to remove prepended "//"
  const url = allContentfulDefaultMetadataLogo?.edges?.[0]?.node?.defaultLogo
    ?.gatsbyImageData?.images?.fallback?.src;

  return url
  return url.startsWith('//') ? url.replace("//", "https://") : url

};

export default useLogo;
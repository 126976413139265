import React, { useState, useEffect, useRef } from 'react'
import { Link, navigate } from 'gatsby'
import styled from 'styled-components'
import gsap from 'gsap'
import { useLocation } from '@reach/router'

import SearchForm from 'components/SearchForm'

import useHeader from 'hooks/useHeader'
import useWindowDimensions from 'hooks/useWindowDimensions'
import usePrevious from 'hooks/usePrevious'

const Wrapper = styled.div`
  display: block;

  ${p => p.theme.media.minWidth('desktop')} {
    display: none;
  }
`

const Toggle = styled.button`
  padding: 10px;
  z-index: 1;
  
  span {
    display: block;
    width: 25px;
    height: 1px;
    background-color: ${p => p.theme.colors.white};
    &:nth-child(1) {
      margin: 0 0 7px 0;
    }
  }
`

const Menu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.3);
  opacity: 0;
  visibility: hidden;
  user-select: none;
  z-index: 2;
`

const MenuPane = styled.div`  
  position: absolute;
  top: 0;
  left: 0;
  width: 80vw;
  max-width: 500px;
  height: 100%;
  background-color: ${p => p.theme.colors.green};
  transform: translateX(-100%);
  display: flex;
  flex-direction: column;  
  overflow: hidden;
`

const MenuPaneHeader = styled.div`
  position: relative;
  height: ${p => p.theme.header.mobileHeight};
  border-bottom: solid 1px ${p => p.theme.colors.xltGreen};
`

const Close = styled.button`
  ${p => p.theme.mixins.vCenter('absolute')}
  left: 5px;
  padding: 10px;  

  i {
    font-size: 24px;
    color: ${p => p.theme.colors.ltGreen};
  }
`

const MenuPaneBody = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`

const MenuPaneBodyPrimary = styled.div`
  flex: 0 0 80vw;
  width: 80vw;
  max-width: 500px;
`

const MenuPaneBodySecondary = styled.div`
  flex: 0 0 80vw;
  width: 80vw;
  max-width: 500px;
  overflow-y: scroll;
`

const MenuPaneBodySecondaryHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  border-bottom: solid 1px ${p => p.theme.colors.xltGreen};

  i {
    ${p => p.theme.mixins.vCenter('absolute')}
    padding: 5px;
    left: 10px;
    font-size: 12px;
  }
`

const MenuItem = styled.div`
  position: relative;
  height: 42px;
  border-bottom: solid 1px ${p => p.theme.colors.xltGreen};  

  ${MenuPaneBodyPrimary} &:last-child {
    border-bottom: none;
  }

  a, [role='link'] {
    ${p => p.theme.mixins.fill('absolute')}
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 15px;
    font-size: 14px;
  }

  i {
    ${p => p.theme.mixins.vCenter('absolute')}
    right: 15px;
    font-size: 12px;
  }
`

const Component = () => {
  const [
    menuOpen,
    setMenuOpen
  ] = useState(false)
  
  const [
    secondaryNavigation,
    setSecondaryNavigation
  ] = useState(null)
  
  const [
    secondaryNavigationOpen,
    setSecondaryNavigationOpen
  ] = useState(false)
  
  const menuRef = useRef()
  const menuPaneRef = useRef()
  const menuPaneBodyRef = useRef()  
  const navigation = useHeader()
  const { height } = useWindowDimensions()
  const location = useLocation()
  const prevLocation = usePrevious(location)

  useEffect(() => {
    menuRef.current.style.height = `${height}px`
  }, [height])
  
  useEffect(() => {
    const onComplete = () => {
      if ( !menuOpen && secondaryNavigationOpen ) {
        setSecondaryNavigationOpen(false)
        setSecondaryNavigation(null)
      }
    }
    
    const tl = gsap.timeline({ onComplete })
    
    if ( menuOpen ) {
      tl.to(menuRef.current, .5, {
        autoAlpha: 1
      })

      tl.to(menuPaneRef.current, .5, {
        x: 0,
        ease: 'power4.out'
      }, '-=.2')
    } else {
      tl.to(menuPaneRef.current, .3, {
        x: '-100%',
        ease: 'power4.out'
      })
      
      tl.to(menuRef.current, .3, {
        autoAlpha: 0
      }, '-=.15')
    }
  }, [menuOpen, secondaryNavigationOpen])

  useEffect(() => {
    const tl = gsap.timeline()

    if ( secondaryNavigationOpen ) {
      tl.to(menuPaneBodyRef.current, .5, {
        x: '-100%',
        ease: 'power4.inOut'
      })
    } else {
      tl.to(menuPaneBodyRef.current, .5, {
        x: 0,
        ease: 'power4.inOut'
      })
    }        
  }, [secondaryNavigationOpen])

  useEffect(() => {
    if ( location !== prevLocation )
      setMenuOpen(false)
  }, [location, prevLocation, setMenuOpen])

  const onMenuClick = ( e ) => {
    if ( menuOpen && !menuPaneRef.current.contains(e.target) ) 
      setMenuOpen(false)
  }

  const onMenuItemClick = ( id ) => {
    const nav = navigation.navigationItems.find(i => i.id === id)

    setSecondaryNavigationOpen(true)
    setSecondaryNavigation(nav)
  }

  const onSearch = ( value ) => {
    navigate(`/search?q=${value}`)
  }

  const onBackClick = () => {
    setSecondaryNavigationOpen(false)
  }

  const renderNavigationItem = ( item ) => {
    return (
      <MenuItem key={item.id}>
        {item.linkLists
         ? <div
               onClick={() => {onMenuItemClick(item.id)}}
               onKeyDown={() => {onMenuItemClick(item.id)}}
               role='link'
               tabIndex={0}>{item.title}</div>
         : <Link to={item.link}>{item.title}</Link>}
        
        {item.linkLists && <i className='icon-chevron-right'></i>}
      </MenuItem>
    )
  }

  const renderLinklist = ( list ) => {
    return (                  
      <React.Fragment key={list.id}>
        <MenuItem>
          <Link to={list.link}>{list.title}</Link>                      
        </MenuItem>
        {!list.hideLinksOnMobile && list.links.map(item => {
           return (
             <MenuItem key={item.id}>
               <Link to={item.link}>{item.title}</Link>                      
             </MenuItem>
           )
         })}
      </React.Fragment>
    )
  }
  
  return (
    <Wrapper>
    <Toggle onClick={() => setMenuOpen(true)}>
      <span />
      <span />
    </Toggle>
    <Menu ref={menuRef} onClick={onMenuClick}>
      <MenuPane ref={menuPaneRef}>
        <MenuPaneHeader>
          <Close onClick={() => setMenuOpen(false)}>
            <i className='icon-close'></i>
          </Close>
        </MenuPaneHeader>
        <MenuPaneBody ref={menuPaneBodyRef}>
          <MenuPaneBodyPrimary>
            <SearchForm onSearch={onSearch} />
            {navigation.navigationItems.map(renderNavigationItem)}
            <MenuItem>
              <a href={`${process.env.GATSBY_SHOPIFY_PUBLIC_URL}/account`}
                 target='_blank'
                 rel='noreferrer'>Account</a>
            </MenuItem>
          </MenuPaneBodyPrimary>
          
          {secondaryNavigation &&
           <MenuPaneBodySecondary>
             <MenuPaneBodySecondaryHeader>
               <i className='icon-chevron-left'
                  role='button'
                  aria-label='Back'
                  tabIndex={0}
                  onKeyDown={onBackClick}
                  onClick={onBackClick}></i>
               {secondaryNavigation.title}               
             </MenuPaneBodySecondaryHeader>
             
             {secondaryNavigation.linkLists.map(renderLinklist)}
           </MenuPaneBodySecondary>}
        </MenuPaneBody>
      </MenuPane>
    </Menu>
    </Wrapper>
  )
}

export default Component

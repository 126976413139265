import { css } from 'styled-components'

export const tH1 = css`
  font-size: 18px;

  ${p => p.theme.media.minWidth('tablet')} {
    font-size: 24px;
  }  
`

export const tH2 = css`
  font-size: 16px;
  letter-spacing: .025em;
  text-transform: uppercase;

  ${p => p.theme.media.minWidth('tablet')} {
    font-size: 22px;
    font-weight: bold;
  }  
`

export const tH3 = css`
  font-size: 17px; 

  ${p => p.theme.media.minWidth('tablet')} {
    font-size: 18px;
  }  
`

export const tH4 = css`
  font-size: 16px; 
`

export const tH5 = css`
  font-size: 14px; 
`

export const tH6 = css`
  font-size: 13px;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: .02em;
  line-height: 1.6em;
`

export const tSmall = css`
  font-size: 12px;
  line-height: 1.9em;
`

export const tXSmall = css`
  font-size: 11px;
  line-height: 1.9em;
`

export const tXXSmall = css`
  font-size: 10px;
  line-height: 1.9em;
`

export const tNormal = css`
  font-size: 13px;
  line-height: 1.6em;
`

export const tNormalImpact = css`
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: .02em;
  line-height: 1.6em;
`

export const tMedium = css`
  font-size: 14px;

  ${p => p.theme.media.minWidth('tablet')} {
    font-size: 16px;
  }
`

export const tLarge = css`
  font-size: 16px;  
  line-height: 1.625em;
`

export const tXLarge = css`
  font-size: 18px;  
  line-height: 1.5em;

  ${p => p.theme.media.minWidth('tablet')} {
    font-size: 20px;
  }

  ${p => p.theme.media.minWidth('desktop')} {
    font-size: 24px;
  }
`

export const tButtonNormal = css`
  font-size: 13px;
  letter-spacing: 1px;
`

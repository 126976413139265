import React, { useContext, useState, useEffect, useRef } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import gsap from 'gsap'

import SearchForm from 'components/SearchForm'

import {
  ButtonDecorated,
  ADecorated
} from 'components/Typography'

import StoreContext from 'context/Store'

const Menu = styled.div`
  position: relative;
`

const MenuItems = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const MenuItem = styled.li`
  position: relative;
  display: inline-block;
  margin: 0 20px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  a, button {
    font-size: 14px;  
  }
`

const SearchMenuItem = styled(MenuItem)`
  display: none;

  ${p => p.theme.media.minWidth('desktop')} {
    display: inline-block;
  }
`

const AccountMenuItem = styled(MenuItem)`
  display: none;

  ${p => p.theme.media.minWidth('tablet')} {
    display: inline-block;
  }
`

const StyledSearchForm = styled(SearchForm)`
  position: absolute;
  top: -7px;
  right: 0;
  width: 200px;
  height: 36px;
  opacity: 0;
  visibility: hidden;

  ${p => p.theme.media.minWidth('large')} {
    width: 240px;
  }

  ${p => p.theme.media.minWidth('xlarge')} {
    width: 275px;
  }
`

const UserMenu = () => {
  const {
    checkout,
    setMiniCartOpen
  } = useContext(StoreContext)

  const [searchOpen, setSearchOpen] = useState(false)
  const searchRef = useRef()  

  useEffect(() => {
    const tl = gsap.timeline()

    if ( searchOpen ) {
      tl.fromTo(searchRef.current, .5, {
        autoAlpha: 0,
        rotationX: 30,
        y: -10
      }, {
        autoAlpha: 1,
        rotationX: 0,
        y: 0,
        ease: 'power4.out'
      })
    } else {
      tl.to(searchRef.current, .25, {
        autoAlpha: 0,
        rotationX: 10,
        y: -5,
        ease: 'power4.out'
      })      
    }    
  }, [searchOpen])

  let to
  
  const onSearchClick = () => {
    setSearchOpen(true)    
  }

  const onSearchClear = () => {
    clearTimeout(to)
    setSearchOpen(true)
  }

  const onSearchBlur = () => {
    to = setTimeout(() => {
      setSearchOpen(false)
    }, 100)
  }

  const onSearch = ( value ) => {
    setSearchOpen(false)
    navigate(`/search?q=${value}`)
  }
  
  const onBagClick = () => {
    setMiniCartOpen(true)
    setSearchOpen(false)
  }
  
  return (
    <Menu>
      <MenuItems>
        <SearchMenuItem>
          <ButtonDecorated
              onClick={onSearchClick}>
            Search
          </ButtonDecorated>
          <StyledSearchForm
              open={searchOpen}
              innerRef={searchRef}
              onSearch={onSearch}
              onBlur={onSearchBlur}
              onClear={onSearchClear} />
        </SearchMenuItem>

        <AccountMenuItem>
          <ADecorated
              href={`${process.env.GATSBY_SHOPIFY_PUBLIC_URL}/account`}
              target='_blank'
              rel='noreferrer'>Account</ADecorated>
        </AccountMenuItem>

        <MenuItem>
          <ButtonDecorated onClick={onBagClick}>
            Bag ({checkout.lineItems.length})
          </ButtonDecorated>
        </MenuItem>
      </MenuItems>
    </Menu>
  )
}

export default UserMenu

import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

const Form = styled.div`
  position: relative;
  width: 100%;
  height: 42px;
  background-color: ${p => p.theme.colors.cream};

  input {
    ${p => p.theme.mixins.fill('absolute')}
    color: ${p => p.theme.colors.dkGreen};
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0 0 0 50px;
    outline: none;
    -webkit-appearance: none;
    font-size: 14px;
    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }

    &::placeholder {
      color: ${p => p.theme.colors.dkGreen};
    }
  }

  i {
    ${p => p.theme.mixins.vCenter('absolute')}
    display: block;
    color: ${p => p.theme.colors.dkGreen};
    outline: none;
    z-index: 2;
    padding: 5px;

    &:first-child {
      font-size: 18px;
      left: 10px;
    }

    &:last-child {
      font-size: 12px;
      right: 10px;
    }
  }
`

const SearchForm = ({
  className,
  onSearch,
  onClear,
  onBlur,
  innerRef,
  open
}) => {
  const [value, setValue] = useState('')
  
  const inputRef = useRef()

  useEffect(() => {
    if ( open ) {            
      setTimeout(() => {
        inputRef.current.focus()
      }, 100)
    }
    
  }, [open])
  
  const onKeyDown = ( e ) => {
    if ( e.keyCode === 13 && onSearch ) {
      onSearch(value, e)
    }    
  }
  
  const onClearClick = ( e ) => {
    setValue('')
    
    if ( onClear ) {
      onClear(e)
    } 
      
    inputRef.current.focus()
  }

  const onSearchClick = ( e ) => {    
    e.preventDefault()
    
    if ( value === '' ) {
      inputRef.current.focus()
    } else {
      if ( onSearch )
        onSearch(value, e)
    }
  }
 
  return (
    <Form ref={innerRef} className={className}>
      <i className='icon-search'
         role='button'
         tabIndex={0}
         aria-label="Search"
         onKeyDown={onSearchClick}
         onClick={onSearchClick}></i>
      <input
          type='search'
          placeholder='Search'
          ref={inputRef}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onChange={e => setValue(e.target.value)}
          value={value} />
      <i className='icon-close'
         role='button'
         aria-label='Clear'
         onKeyDown={onClearClick}
         onClick={onClearClick}
         tabIndex={0}></i>
    </Form>
  )
}

export default SearchForm

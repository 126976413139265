import { useStaticQuery, graphql } from 'gatsby'

const useFooter = () => {
  const { allContentfulFooter } = useStaticQuery(
    graphql`
      query Footer {
        allContentfulFooter(
          limit: 1, 
          filter: { title: { ne: "TEMPLATE" }}
        ) {
          nodes {
            linkLists {
              title
              links {
                title
                link
              }
            }            
            socialLinks {
              title
              links {
                link
                title
              } 
            }
            address {
              title
              body {
                raw
              }
            }
          }
        }
      }
    `
  )

  return allContentfulFooter.nodes[0]
}

export default useFooter

import {
  flow,
  flatMap,
  map,
  pick,
  uniqBy,
  sortBy,
  reduce,
  union,
  find,
  each,
} from "lodash/fp";

import { SIZE_MAP, SWATCH_COLORS } from "constants/product";

export const getUrl = (handle) => {
  return `/products/${handle}`;
};

export const getPrice = (amount, options) => {
  return Intl.NumberFormat(undefined, {
    minimumFractionDigits: 0,
    currency: "USD",
    style: "currency",
    ...options,
  }).format(amount);
};

export const getCollections = (products, fields = ["handle", "title"]) => {
  return flow(
    flatMap("collections"),
    map(pick(fields)),
    uniqBy("handle"),
    sortBy("title")
  )(products);
};

export const getOptionGroups = (products) => {
  return flow(
    reduce((groups, { options, productType }) => {
      each(({ name, values }) => {
        const group = find((g) => g.name === name)(groups);
        if (group) {
          group.values = union(group.values, values);
        } else {
          groups.push({ name: name, values, productType });
        }
      })(options);

      const modifiedGroups = sortBy("name")(groups);

      return groups;
    }, []),
    map((group) => {
      const sizeMap = ["XS", "S", "M", "L", "XL", "XXL", "2XL", "OS"];
      group.values = sortBy(
        (v) =>
          (sizeMap.indexOf(v) === -1
            ? parseFloat(v) + sizeMap.length
            : sizeMap.indexOf(v) === -1) ?? v
      )(group.values);
      return group;
    })
  )(products);
};

export const translateSize = (size, locale, type) => {
  const sizes = find((l) => {
    // Here we assume the default on Shopify is "us"
    return l.fr === (parseFloat(size) || size);
  })(SIZE_MAP[type]);

  return sizes ? sizes[locale] : size;
};

export const hexFromColorOption = (color) => {
  return SWATCH_COLORS[color];
};

import React, { useContext } from 'react'
import { Helmet } from "react-helmet";
import { ThemeContext } from 'styled-components'
import { createGlobalStyle } from 'styled-components'

import Header from 'components/Header'
import Footer from 'components/Footer'

import styled from 'styled-components'
import * as media from 'styles/media'
import colors from 'styles/colors'
import header from 'styles/header'

import { AnimatePresence } from 'framer-motion'

const Main = styled.main`
  position: relative;
  overflow-x: hidden;
  flex: 1;
`

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${colors.green};
    color: ${colors.white};
    padding-top: ${p => p.headerPadding ? header.mobileHeight : 0};

    ${media.minWidth('tablet')} {
      padding-top: ${p => p.headerPadding ? header.tabletHeight : 0};
    }
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - ${p => p.headerPadding ? header.mobileHeight : '0px'});    
    ${media.minWidth('tablet')} {
      min-height: calc(100vh - ${p => p.headerPadding ? header.tabletHeight : '0px'});
    }
  }

  ::selection {
    background: ${p => p.theme.colors.xdkGreen};
  }
  ::-moz-selection {
    background: ${p => p.theme.colors.xdkGreen};
  }
`

const StyledFooter = styled(Footer)`
  ${p => p.theme.media.minWidth('tablet')} {
    display: ${p => p.hideTablet ? 'none' : 'block'};
  }  
`

const App = ({ children, ...props }) => {
  const theme = useContext(ThemeContext)
  
  return (
    <>
      <GlobalStyle headerPadding={theme.global.headerPadding} />
      <Helmet>
        <script type="text/javascript" async="" src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=RnFxQL"></script>
      </Helmet>
      <Header />
      <Main>
        <AnimatePresence exitBeforeEnter>
          {children}
        </AnimatePresence>
      </Main>
      <StyledFooter hideTablet={props.uri === '/bag'} />
    </>
  )
}

export default App

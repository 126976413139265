export const fill = position => {
  return `
    position: ${position};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `
}

export const center = position => {
  return `
    position: ${position};
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  `
}

export const vCenter = position => {
  return `
    position: ${position};
    top: 50%;
    transform: translateY(-50%);
  `
}

export const hCenter = position => {
  return `
    position: ${position};
    left: 50%;
    transform: translateX(-50%);
  `
}


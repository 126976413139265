export const SWATCH_COLORS = {
  'White': '#ffffff',
  'Heritage White': '#ffffff',
  'Champagne': '#D8C0A0',
  'Black':     '#000000'
}

export const SIZE_LOCALES = [
  { label: 'US', value: 'us' },
  { label: 'French', value: 'fr' }
]

export const SIZE_MAP = {
  "Women's Tops": [
    { us: 0,  fr: 32 },
    { us: 2,  fr: 34 },
    { us: 4,  fr: 36 },
    { us: 6,  fr: 38 },
    { us: 8,  fr: 40 },
    { us: 10, fr: 42 },
    { us: 12, fr: 44 }
  ],
  "Women's Blazers": [
    { us: 0,  fr: 32 },
    { us: 2,  fr: 34 },
    { us: 4,  fr: 36 },
    { us: 6,  fr: 38 },
    { us: 8,  fr: 40 },
    { us: 10, fr: 42 },
    { us: 12, fr: 44 }
  ],
  "Women's Bottoms": [
    { us: 23, fr: 32 },
    { us: 24, fr: 34 },
    { us: 25, fr: '-' },
    { us: 26, fr: 36 },
    { us: 27, fr: '-' },
    { us: 28, fr: 38 },
    { us: 29, fr: '-' },
    { us: 30, fr: 40 },
    { us: 31, fr: '-' },
    { us: 32, fr: 42 },
    { us: 33, fr: '-' },
    { us: 34, fr: 44 }
  ],
  "Women's Shoes": [
    { us: 4,    fr: 35 },
    { us: 4.5,  fr: 35.5 },
    { us: 5,    fr: 36 },
    { us: 5.5,  fr: 36.5 },
    { us: 6,    fr: 37 },
    { us: 6.5,  fr: 37.5 },
    { us: 7,    fr: 38 },
    { us: 7.5,  fr: 38.5 },
    { us: 8,    fr: 39 },
    { us: 8.5,  fr: 39.5 },
    { us: 9,    fr: 40 },
    { us: 9.5,  fr: 40.5 },
    { us: 10,   fr: 41 },
    { us: 10.5, fr: 41.5 },
    { us: 11,   fr: 42 },
    { us: 11.5, fr: 42.5 },
    { us: 12,   fr: 43 }
  ],
  "Men's Tops": [
    { us: 34, fr: 44 },
    { us: 36, fr: 46 },
    { us: 38, fr: 48 },
    { us: 40, fr: 50 },
    { us: 42, fr: 52 },
    { us: 44, fr: 54 },
    { us: 46, fr: 56 },
    { us: 48, fr: 58 }
  ],
  "Men's Shirts": [
    { us: 14,   fr: '-' },
    { us: 14.5, fr: 37 },
    { us: 15,   fr: 38 },
    { us: 15.5, fr: 39 },
    { us: 16,   fr: 40 },
    { us: 16.5, fr: 41 },
    { us: 17,   fr: 42 },
    { us: 17.5, fr: 43 },
    { us: 18,   fr: 44 }
  ],
  "Men's Bottoms": [
    { us: 28, fr: 34 },
    { us: 29, fr: '-' },
    { us: 15, fr: 38 },
    { us: 30, fr: 36 },
    { us: 31, fr: '-' },
    { us: 32, fr: 38 },
    { us: 33, fr: '-' },
    { us: 34, fr: 40 },
    { us: 36, fr: 42 },
    { us: 38, fr: 44 },
    { us: 40, fr: 46 },
    { us: 42, fr: 48 }
  ]
}

import React, { useState } from 'react'
import styled from 'styled-components'
import fetch from 'node-fetch'
import Promise from 'bluebird'

import RichText from 'components/RichText'
import useFooter from 'hooks/useFooter'
import { StaticImage } from "gatsby-plugin-image" 

import {
  Container,
  Row,
  Col
} from 'components/Grid'

import {
  H6,
  Paragraph  
} from 'components/Typography'

import {
  Form,
  TextInputWithSubmit,
  Checkboxes,
  Checkbox,
  TextInput,
  Submit
} from 'components/Form'

import DynamicLink from 'components/DynamicLink'

fetch.promise = Promise

const FooterEl = styled.footer`
  position: relative;  
  margin: 40px 0 0 0;

  ${p => p.theme.media.minWidth('tablet')} {
    margin: 40px 0 0 0;
  }
`

const Main = styled.div`
  background-color: ${p => p.theme.colors.dkGreen};
  padding: 32px 0;

  ${p => p.theme.media.minWidth('tablet')} {
    padding: 30px 0 40px 0;
  }
`

const NewsletterForm = styled(Form)`
  position: relative;  
  margin: 0 0 40px 0;
  padding: 0 0 40px 0;

  &.is-loading {
    opacity: .5
  }

  ${p => p.theme.media.minWidth('tablet-large')} {
    margin: 0;
    padding: 0;
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: -${p => p.theme.grid.containerPadding.mobile}px;
    display: block;
    content: '';
    width: calc(100% + ${p => p.theme.grid.containerPadding.mobile * 2}px);
    height: 1px;    
    background-color: ${p => p.theme.colors.white};

    ${p => p.theme.media.minWidth('tablet')} {
      left: -${p => p.theme.grid.containerPadding.tablet}px;
      width: calc(100% + ${p => p.theme.grid.containerPadding.tablet * 2}px);
    }

    ${p => p.theme.media.minWidth('tablet-large')} {
      display: none;
    }
  }
`

const SocialLinksMobile = styled.div`
  ${p => p.theme.media.minWidth('tablet-large')} {
    display: none;
  }
`

const SocialLinksDesktop = styled.div`
  display: none;
  margin: 0 ${p => ((1/p.theme.grid.columnCount.tablet)*100)}% 0 0;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    ${p => p.theme.typography.tNormal}
  }

  ${p => p.theme.media.minWidth('tablet-large')} {
    display: block;
    
    ul {
      display: flex;
      align-items: center;
    }
    
    li {
      display: inline-flex;
      align-items: center;
    }
  }
`

const LinkList = styled.div`
  position: relative;
  margin: 0 0 40px 0;

  ${SocialLinksMobile} & {
    margin: 0;
  }

  ${p => p.theme.media.minWidth('desktop')} {
    margin: 0;
  }
`

const BlockTitle = styled(H6)`
  margin: 0 0 0 0;
  position: relative;
  white-space: nowrap;
`

const LinkListItems = styled.ul`
  position: relative;
  margin: 1em 0;
  padding: 0;
  list-style: none;
`

const LinkListItem = styled.li`
  position: relative;

  a {
    ${p => p.theme.typography.tSmall};
  }
`

const Kicker = styled.div`  
  color: ${p => p.theme.colors.black};
  background-color: ${p => p.theme.colors.beige};
  
  & ${Container} {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;

    ${p => p.theme.media.minWidth('tablet')} {
      justify-content: space-between;
      height: 40px;
    }
  }
`

const Copyright = styled.div`
  ${p => p.theme.typography.tNormal}
`

const Address = styled(RichText)`
  margin-top: 16px;
`

const Footer = ({ className }) => {
  const { linkLists, socialLinks, address } = useFooter()
  const [email, setEmail] = useState('')
  const [women, setWomen] = useState(false)
  const [men, setMen] = useState(false)
  const [all, setAll] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  
  const listUrl = ( listId ) => {
    const { GATSBY_KLAVIYO_API_KEY } = process.env
    const kListUrl = `https://a.klaviyo.com/api/v1/list/${listId}`
    return `${kListUrl}/members?api_key=${GATSBY_KLAVIYO_API_KEY}`
  }
  
  const onFormSubmit = (e) => {
    e.preventDefault()

    if ( !email || email === '' )
      return alert('Please enter an email.')

    if ( !women && !men && !all )
      return alert('Please select a list.')
    
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'        
      },
      body: new URLSearchParams({
        email,
        confirm_optin: 'true'
      })
    }    
    
    let promises = []

    if ( women )
      promises.push(
        fetch(listUrl('WbTENv'), options)
          .catch(err => console.error(err))
      )
    
    if ( men )
      promises.push(
        fetch(listUrl('YiQzGy'), options)
          .catch(err => console.error(err))
      )    

    if ( all )
      promises.push(
        fetch(listUrl('WAtrDY'), options)
          .catch(err => console.error(err))
      )
    
    setLoading(true)
    
    Promise.all(promises).then(() => {
      setLoading(false)
      setEmail('')
      setWomen(false)
      setMen(false)
      setAll(false)

      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    })   
  }
  
  const renderLinkList = ({ title, links }, i) => {
    return (
      <Col key={i} tblg={8}>
        <LinkList>
          <BlockTitle>
            {title}
          </BlockTitle>
          <LinkListItems>
            {links.map(renderLinkListItem)}
          </LinkListItems>
        </LinkList>
      </Col>
    )    
  }
  
  const renderLinkListItem = (item, i=0) => {        
    return (
      <LinkListItem key={i}>        
        <DynamicLink {...item} />
      </LinkListItem>                     
    )
  }
  
  return (
    <FooterEl className={className}>
      <Main>
        <Container>
          <Row tbFlexDir='row-reverse'>
            <Col tblg={10} tblgPush={1} lg={9} xl={7}>
              <BlockTitle>
                Become a Part of the two : minds Community
              </BlockTitle>
              {success ? (
                 <Paragraph>
                   Thanks for signing up!
                 </Paragraph>
               ) : (
                 <Paragraph>
                   Receive first access to the very best products,
                   inspiration and services.
                 </Paragraph>
               )}
              <NewsletterForm onSubmit={onFormSubmit} className={loading && 'is-loading'}>
                <Checkboxes css={`margin-bottom: 1.5em;`}>
                  <Checkbox
                      label='Women'
                      name='women'
                      value={true}
                      checked={women}
                      onChange={e => setWomen(e.target.checked)} />
                  <Checkbox
                      label='Men'
                      name='men'
                      value={true}
                      checked={men}
                      onChange={e => setMen(e.target.checked)} />
                  <Checkbox
                      label='All'
                      name='all'
                      value={true}
                      checked={all}
                      onChange={e => setAll(e.target.checked)} />
                </Checkboxes>

                <TextInputWithSubmit>
                  <TextInput
                      type='email'
                      name='email'
                      placeholder='Email'
                      value={email}
                      onChange={e => setEmail(e.target.value)} />
                  <Submit>Sign Up</Submit>
                </TextInputWithSubmit>
              </NewsletterForm>
            </Col>
            <Col tblg={13} lg={12} xl={10} xxl={9}>
              <Row>            
                {linkLists.map(renderLinkList)}
                <Col tblg={8}>
                  <LinkList>
                    <BlockTitle>{address.title}</BlockTitle>
                    <Address size='small' body={address.body} />
                  </LinkList>
                </Col>
                <SocialLinksMobile>
                  {renderLinkList(socialLinks)}
                </SocialLinksMobile>
              </Row>        
            </Col>
          </Row>          
        </Container>
      </Main>
      
      <Kicker>
        <Container>
          <Copyright>
            © {(new Date()).getFullYear()} Two Minds All Rights Reserved 
          </Copyright>
          <SocialLinksDesktop>
            <ul>
              {socialLinks.links.map((link,i) => {
                return (
                  <li key={i}>
                    {link.title == 'Instagram' && 
                      <svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px">
                        <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"/>
                      </svg>
                    }
                    <DynamicLink {...link} />
                  </li>
                )
              })}
            </ul>
          </SocialLinksDesktop>
        </Container>
      </Kicker>
    </FooterEl>
  )
}

export default Footer

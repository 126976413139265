import React from 'react'
import { Link } from 'gatsby'

const DynamicLink = ({ link, title }) => {
  const r = new RegExp('^(?:[a-z]+:)?//', 'i'),
        isAbsolute = r.test(link),
        isBrowser = typeof window !== 'undefined'
  
  let isExternal = false

  if ( isAbsolute &&
       isBrowser &&
       new URL(link).hostname !== window.location.hostname ) {
    isExternal = true
  }
  
  return (
    isExternal ?
    <a href={link}
       target='_blank'
       rel='noreferrer'>{title}</a> :
    <Link to={link}>
      {title}
    </Link>    
  )
}

export default DynamicLink

import styled, { css } from 'styled-components'

import {
  containerPadding,
  columnCount,
  columnGutter
} from 'styles/grid'

import {
  minWidth,
  maxWidth
} from 'styles/media'

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 ${containerPadding.mobile}px;

  ${maxWidth('tablet')} {
    ${p => {
      return p.fullBleedMobile && 'padding: 0;'
    }}
  }

  ${minWidth('tablet')} {
    padding: 0 ${p => p.fullBleedTablet ? 0 : containerPadding.tablet}px;
  }  
`

export const Row = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: ${p => p.alignItems || 'flex-start'};
  margin-left: -${columnGutter.mobile / 2}px;
  margin-right: -${columnGutter.mobile / 2}px;

  ${minWidth('tablet')} {
    margin-left: -${columnGutter.tablet / 2}px;
    margin-right: -${columnGutter.tablet / 2}px;    
  }

  ${p => renderRowStyles}
`

export const Col = styled.div`
  position: relative;
  padding-left: ${columnGutter.mobile / 2}px;
  padding-right: ${columnGutter.mobile / 2}px;
  flex: 0 0 100%;
  max-width: 100%;  

  ${minWidth('tablet')} {
    padding-left: ${columnGutter.tablet / 2}px;
    padding-right: ${columnGutter.tablet / 2}px;    
  }

  ${p => renderColumnStyles}
`

const renderRowStyles = ({
  tbFlexDir,
  dkFlexDir,
  dkAlignItems,
  dkGutterFactor,
  lgGutterFactor
}) => {
  let styles = ''

  if ( tbFlexDir )
    styles += `
      ${minWidth('tablet')} {
        flex-direction: ${tbFlexDir};
        justify-content: flex-${tbFlexDir.match(/reverse/) ? 'end' : 'start'};
      }
    `
  
  if ( dkFlexDir )
    styles += `
      ${minWidth('desktop')} {
        flex-direction: ${dkFlexDir};
        justify-content: flex-${dkFlexDir.match(/reverse/) ? 'end' : 'start'};
      }
    `

  if ( dkAlignItems )
    styles += `
      ${minWidth('desktop')} {
        align-items: ${dkAlignItems};
      }
    `

  if ( dkGutterFactor )
    styles += `
      ${minWidth('desktop')} {
        margin-left: -${(columnGutter.tablet / 2) * dkGutterFactor}px;
        margin-right: -${(columnGutter.tablet / 2) * dkGutterFactor}px;    
        & ${Col} {
          padding-left: ${(columnGutter.tablet / 2) * dkGutterFactor}px;
          padding-right: ${(columnGutter.tablet / 2) * dkGutterFactor}px;    
        }
      }      
    `
  
  if ( lgGutterFactor )
    styles += `
      ${minWidth('large')} {
        margin-left: -${(columnGutter.tablet / 2) * lgGutterFactor}px;
        margin-right: -${(columnGutter.tablet / 2) * lgGutterFactor}px;    
        & ${Col} {
          padding-left: ${(columnGutter.tablet / 2) * lgGutterFactor}px;
          padding-right: ${(columnGutter.tablet / 2) * lgGutterFactor}px;    
        }
      }      
    `
    

  return css`
    ${styles}
  `
}

const renderColumnStyles = ({
  mb,
  mbPush,
  mblg,
  tb,
  tbPush,
  tblg,
  tblgPush,
  dk,
  dkPush,
  lg,
  lgPush,
  xl,
  xlPush,
  xxl,
  xxlPush
}) => {
  let styles = ''
  
  if ( mb )
    styles += `
      flex: 0 0 ${(mb/columnCount.mobile)*100}%;
      max-width: ${(mb/columnCount.mobile)*100}%;
    `

  if ( mbPush )
    styles += `
      margin-left: ${(mbPush/columnCount.mobile)*100}%;
    `      
  
  if ( mblg )
    styles += `
      ${minWidth('mobile-large')} {
        flex: 0 0 ${(mblg/columnCount.mobile)*100}%;
        max-width: ${(mblg/columnCount.mobile)*100}%;
      }
    `

  if ( tb )
    styles += `
      ${minWidth('tablet')} {
        flex: 0 0 ${(tb/columnCount.tablet)*100}%;
        max-width: ${(tb/columnCount.tablet)*100}%;
      }      
    `

  if ( tbPush )
    styles += `
      ${minWidth('tablet')} {
        margin-left: ${(tbPush/columnCount.tablet)*100}%;
      }      
    `      
  
  if ( tblg )
    styles += `
      ${minWidth('tablet-large')} {
        flex: 0 0 ${(tblg/columnCount.tablet)*100}%;
        max-width: ${(tblg/columnCount.tablet)*100}%;
      }      
    `

  if ( tblgPush )
    styles += `
      ${minWidth('tablet-large')} {
        margin-left: ${(tblgPush/columnCount.tablet)*100}%;
      }      
    `      

  if ( dk )
    styles += `
      ${minWidth('desktop')} {
        flex: 0 0 ${(dk/columnCount.tablet)*100}%;
        max-width: ${(dk/columnCount.tablet)*100}%;
      }      
    `

  if ( dkPush )
    styles += `
      ${minWidth('desktop')} {
        margin-left: ${(dkPush/columnCount.tablet)*100}%;
      }      
    `      

  if ( lg )
    styles += `
      ${minWidth('large')} {
        flex: 0 0 ${(lg/columnCount.tablet)*100}%;
        max-width: ${(lg/columnCount.tablet)*100}%;
      }      
    `

  if ( lgPush )
    styles += `
      ${minWidth('large')} {
        margin-left: ${(lgPush/columnCount.tablet)*100}%;
      }      
    `      
  
  if ( xl )
    styles += `
      ${minWidth('xlarge')} {
        flex: 0 0 ${(xl/columnCount.tablet)*100}%;
        max-width: ${(xl/columnCount.tablet)*100}%;
      }      
    `

  if ( xlPush )
    styles += `
      ${minWidth('xlarge')} {
        margin-left: ${(xlPush/columnCount.tablet)*100}%;
      }      
    `      

  if ( xxl )
    styles += `
      ${minWidth('xxlarge')} {
        flex: 0 0 ${(xxl/columnCount.tablet)*100}%;
        max-width: ${(xxl/columnCount.tablet)*100}%;
      }      
    `

  if ( xxlPush )
    styles += `
      ${minWidth('xxlarge')} {
        margin-left: ${(xxlPush/columnCount.tablet)*100}%;
      }      
    `      

  return css`
    ${styles}
  `
}

import { Link } from 'gatsby'
import styled from 'styled-components'

export const ButtonPrimary = styled.button`
  ${p => p.theme.button.primaryCss}  
`

export const ButtonSecondary = styled.button`
  ${p => p.theme.button.secondaryCss}  
`

export const ButtonTertiary = styled.button`
  ${p => p.theme.button.tertiaryCss}  
`

export const ButtonPrimaryLink = styled(Link)`
  ${p => p.theme.button.primaryCss};
`



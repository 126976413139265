import React, {
  useEffect,
  useState
} from 'react'

import { Link } from 'gatsby'
import styled from 'styled-components'

import Navigation from 'components/Navigation'
import MiniCart from 'components/MiniCart'
import Svg from 'components/Svg'

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${p => p.theme.header.mobileHeight};
  background-color: ${p => p.opaque ? p.theme.colors.green : 'transparent'};
  text-align: center;
  z-index: 10;
  transition: background-color 300ms cubic-bezier(0.25, 0.1, 0.25, 1);

  ${p => p.theme.media.minWidth('tablet')} {
    height: ${p => p.theme.header.tabletHeight};
  }
`

const Logo = styled.div`
  ${p => p.theme.mixins.center('absolute')};
  width: 130px;
  z-index: 2;

  ${p => p.theme.media.minWidth('tablet')} {
    width: 155px;
  }
`

const Component = () => {
  const [opaque, setOpaque] = useState(false)
  
  useEffect(() => {
    window.addEventListener('scroll', onWindowScroll)

    return () => {
      window.removeEventListener('scroll', onWindowScroll)
    }
  }, [])

 
  const onWindowScroll = e => {
    setOpaque(e.currentTarget.scrollY > 0)
  }
  
  return (
    <Header opaque={opaque}>
      <Logo>
        <Link to="/">
          <Svg filename="logo-inline-white" width={155} height={24} />
        </Link>
      </Logo>
      <Navigation />
      <MiniCart />
    </Header>
  )
}

export default Component

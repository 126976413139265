import React from 'react'
import styled from 'styled-components'

export const Form = styled.form`
  position: relative;
`

export const Checkboxes = styled.div`
  display: flex;
`

const CheckboxEl = styled.div`
  input {
    display: none;
  }

  label {
    ${p => p.theme.typography.tXSmall}
    cursor: pointer;
  }

  label span {
    display: inline-block;
    vertical-align: middle;
  }

  input + label:before {
    content: '';
    display: inline-block;
    margin: 0 8px 0 0;
    width: 10px;
    height: 10px;
    border: solid 1px ${p => p.theme.colors.white};
    vertical-align: middle;  
  }

  input:checked + label:before {
    background-color: ${p => p.theme.colors.white};
  }

  ${Checkboxes} & {
    margin: 0 10px;
    
    &:first-child {
      margin-left: 0;
    }
    
    &:last-child {
      margin-right: 0;
    }    
  }
`

export const Checkbox = ({
  label,
  name,
  value,
  checked,
  onChange
}) => {
  return (
    <CheckboxEl>
      <input
          id={`${label}-${name}`}
          type='checkbox'
          name={name}
          value={value}
          checked={checked}
          onChange={onChange} />
      <label htmlFor={`${label}-${name}`}>
        <span>{label}</span>
      </label>
    </CheckboxEl>
  )
}

const TextInputEl = styled.div`
  input {
    ${p => p.theme.typography.tSmall};
    border: solid 1px ${p => p.theme.colors.white};
    border-radius: 0;
    background: transparent;
    color: ${p => p.theme.colors.white};
    outline: none;
    -webkit-appearance: none;
    height: 36px;
    padding: 10px;
    width: 100%;

    &::-webkit-input-placeholder {
      color: ${p => p.theme.colors.white};
    }
    &:-moz-placeholder {
      color: ${p => p.theme.colors.white};
      opacity:  1;
    }
    &::-moz-placeholder {
      color: ${p => p.theme.colors.white};
      opacity:  1;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: ${p => p.theme.colors.white};
    }
    &::-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: ${p => p.theme.colors.white};
    }
    &::placeholder {
      color: ${p => p.theme.colors.white};
    }
  }  
`

export const TextInput = ({ 
  label, 
  name, 
  value, 
  placeholder,
  type,
  onChange
}, props) => {
  const renderLabel = () => {
    if ( !label ) return 

    return <label>{label}</label>
  }
  return (
    <TextInputEl>
      {renderLabel()}
      <input
          type={type || 'text'}
          name={name} 
          value={value}
          onChange={onChange}
          placeholder={placeholder} />
    </TextInputEl>
  )
}

export const Submit = styled.button`
  ${p => p.theme.typography.tSmall};
  line-height: 1em;
  padding: 10px;
  height: 36px;
  color: ${p => p.theme.colors.white};
  background: transparent;
  border: solid 1px ${p => p.theme.colors.white};
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
`

export const TextInputWithSubmit = styled.div`
  display: flex;
  width: 100%;

  & ${TextInputEl} {
    flex: 1;    
  }

  & ${Submit} {
    margin: 0 0 0 -1px;
  }
`

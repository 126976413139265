import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BLOCKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const Wrapper = styled.div`
  ${p => p.theme.typography[p.size === 'small' ? 'tSmall' : 'tLarge']};

  h2 {
    ${p => p.theme.typography.tH2};
  }

  h3 {
    ${p => p.theme.typography.tH3};
  }

  h4 {
    ${p => p.theme.typography.tH4};
  }

  h5 {
    ${p => p.theme.typography.tH5};
  }

  h6 {
    ${p => p.theme.typography.tH6};
  }

  > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const RichText = ({ body, size, className }) => {  
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <GatsbyImage
              image={getImage(node.data.target)}
              alt={node.data.target.title || ''} />
        )
      }
    },
    renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text])
  }
  
  return (
    <Wrapper size={size} className={className}>
      {renderRichText(body, options)}
    </Wrapper>
  )
}

export default RichText

import React, { useState, useEffect } from 'react'
import Client from 'shopify-buy'
import facebookAddToCart from '../utils/facebookTracking'

import StoreContext from 'context/Store'

import {
  SIZE_LOCALES
} from 'constants/product'
import useLogo from '../hooks/useLogo'

const client = Client.buildClient({
  domain: process.env.GATSBY_SHOPIFY_STORE_PRETTY_URL,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN
})

const Store = ({ children }) => {
  const [checkout, setCheckout] = useState({ lineItems: [] })
  const [addingVariantStatus, setAddingVariantStatus] = useState('ready')
  const [miniCartOpen, setMiniCartOpen] = useState(false)
  const [collectionFilters, setCollectionFilters] = useState([])
  const [collectionSizeLocale, setCollectionSizeLocale] = useState(SIZE_LOCALES[0])
  const [collectionSort, setCollectionSort] = useState('latest')
  const [collectionPage, setCollectionPage] = useState(1)
  const [collectionFilterModalActive, setCollectionFilterModalActive] = useState(false)
  const [collectionSortModalActive, setCollectionSortModalActive] = useState(false)  
  const defaultLogo = useLogo()
  
  useEffect(() => {
    const initializeCheckout = async () => {
      const isBrowser = typeof window !== 'undefined'
      
      const checkoutId =
        isBrowser ?
        localStorage.getItem('shopify_checkout_id')
        : null

      const setCheckoutInState = checkout => {
        if ( isBrowser )
          localStorage.setItem(
            'shopify_checkout_id',
            checkout.id
          )

        setCheckout(prevState => {
          return {
            ...prevState,
            ...checkout
          }
        })
      }

      const createCheckout = () => client.checkout.create()
      const fetchCheckout = id => client.checkout.fetch(id)
      
      if ( checkoutId ) {                
        try {
          const checkout = await fetchCheckout(checkoutId)
          if ( !checkout.completedAt ) {
            setCheckoutInState(checkout)
          }
        } catch (e) {
          localStorage.removeItem('shopify_checkout_id')
        }
      } else {
        setCheckoutInState(await createCheckout())
      }      
    }    
    
    initializeCheckout()
  }, [])

  const resetURLParam = () => {
    const isBrowser = typeof window !== 'undefined'

    if (isBrowser) {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete('page')
  
      window.history.replaceState({}, document.title, newUrl.href);
    }
  }

  const toggleCollectionFilter = filter => {    
    resetURLParam()
    setCollectionFilters(filters => {
      if ( filters.includes(filter) ) {
        return filters.filter(f => f !== filter)
      } else {
        return [...filters, filter]
      }
    })
  }

  const clearCollectionFilters = () => {
    resetURLParam()
    setCollectionFilters([])
  }

  const addVariantToCart = async (variant, quantity=1) => {
    setAddingVariantStatus('adding')
    
    const lineItems = [{
      variantId: variant.id,
      quantity,
      customAttributes: [{
        key: 'vendor',
        value: variant.product && variant.product.vendor
      }]
    }]

    return client
      .checkout
      .addLineItems(checkout.id, lineItems)
      .then(checkout => {
        setCheckout(checkout)
        setAddingVariantStatus('success')
        facebookAddToCart(variant)

        setTimeout(() => {
          setAddingVariantStatus('ready')
        }, 3000)
      })
  }


  const updateLineItem = ( id, quantity ) => {
    const lineItems = [{ id, quantity }]
    
    return client
      .checkout
      .updateLineItems(checkout.id, lineItems)
      .then(setCheckout)
  }
  

  const removeLineItem = ( lineItemId ) => {
    return client
      .checkout
      .removeLineItems(checkout.id, [lineItemId])
      .then(setCheckout)
  }
  
  return (
    <StoreContext.Provider
        value={{
          client,
          checkout,
          collection: {
            filters: collectionFilters,
            setFilters: setCollectionFilters,
            toggleFilter: toggleCollectionFilter,            
            clearFilters: clearCollectionFilters,
            sort: collectionSort,
            setSort: setCollectionSort,
            filterModalActive: collectionFilterModalActive,
            setFilterModalActive: setCollectionFilterModalActive,
            sortModalActive: collectionSortModalActive,
            setSortModalActive: setCollectionSortModalActive,
            sizeLocale: collectionSizeLocale,
            setSizeLocale: setCollectionSizeLocale,
            page: collectionPage,
            setPage: setCollectionPage
          },
          addingVariantStatus,
          addVariantToCart,
          removeLineItem,
          updateLineItem,
          miniCartOpen,
          setMiniCartOpen,
          defaultLogo
        }}>
      {children}
    </StoreContext.Provider>
  )
}

export default Store
